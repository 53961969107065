import React, { memo } from 'react';
import trimEnd from 'lodash/trimEnd';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/system';
import config from '@configFile';
import { DealPostType } from '@types';
import MonetizingLink from '@components/MonetizingLink';
import { addAffiliateTagToLink } from '@utils/formatUtils';
import {
  getProductLink,
  parseTitle,
  getPostPrice,
  hashASIN
} from '@utils/index';
import dealPostOptions from '@utils/dealPostOptions';
import { standardTextStyle, standardLinkStyle } from '@utils/styles';
import colors from '@utils/colors';

interface DealPostFeedCardProps {
  deal: DealPostType;
  tag?: string;
  isExpired?: boolean;
}

function getPhraseForASIN(asin: string) {
  const index = hashASIN(asin) % dealPostOptions.length;
  return dealPostOptions[index];
}

const DealPostSummary = ({
  deal,
  tag = config.AFFILIATE_TAGS.FEED,
  isExpired = false
}: DealPostFeedCardProps) => {
  const { title, extraDeal, extraDealLink } = deal;
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const priceDisplayStyle = {
    ...standardTextStyle,
    fontWeight: 700
  };

  const randomDealPostOption = getPhraseForASIN(deal.ASIN);

  const renderPostPrice = () => {
    if (isExpired) {
      return null;
    }
    return (
      <Typography sx={priceDisplayStyle} display="inline">
        {getPostPrice(deal, true)}
      </Typography>
    );
  };

  return (
    <Box marginTop="8px" component="section">
      <Typography
        variant="body1"
        sx={{
          ...standardTextStyle,
          color: theme.palette.text.primary
        }}
        component="span"
      >
        {isExpired
          ? "This deal is unavailable now for the price we think it should be considered a deal at but here's the information we have for Amazon's"
          : randomDealPostOption}

        <Typography
          display="inline"
          component="span"
          margin="0 4px"
          variant="body1"
        >
          <MonetizingLink
            href={getProductLink(deal.ASIN, tag, deal?.sLink || '')}
            target="_blank"
            underline="hover"
            sx={{
              ...standardLinkStyle,
              color: isDarkMode ? theme.palette.linkColor : colors.linkColor,
              fontWeight: 700
            }}
            item="deal-post-feed-card-summary"
            clickType={getProductLink(deal.ASIN, tag, deal?.sLink || '')}
            tag={tag}
          >
            {trimEnd(parseTitle(title), isExpired ? '.' : ``)}
            {isExpired ? '.' : ``}
          </MonetizingLink>
        </Typography>
        {isExpired ? '' : `for`}
        {renderPostPrice()}
        {isExpired && extraDeal && extraDealLink ? (
          <span>
            {' '}
            We did find this item was eligible for the current promotion:{' '}
            <Link
              href={addAffiliateTagToLink(extraDealLink, tag)}
              target="_blank"
            >
              {extraDeal}
            </Link>
            .
          </span>
        ) : (
          ''
        )}
        {!isExpired && extraDeal ? (
          <span>
            {' '}
            Deal eligible for the current promotion:{' '}
            <Link
              href={addAffiliateTagToLink(extraDealLink, tag)}
              target="_blank"
            >
              {extraDeal}
            </Link>
            .
          </span>
        ) : (
          ''
        )}
      </Typography>
    </Box>
  );
};

export default memo(DealPostSummary);
